import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BrandContext } from '../../contexts/brand-context';
import { UserContext } from '../../contexts/user-context';
import { FormatPhoneInput, GetEmailRegex, ReactSelectInputNoAutoComplete, toTitleCase } from "../../services/utilities";
import { Helmet } from 'react-helmet-async';
import { useForm, Controller } from "react-hook-form";
import apiPublic from '../../services/api-public';
import SpinnerLoader from '../layout/spinner-loader';
import { DateTime } from 'luxon';
import apiTools from '../../services/api-tools';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import { Dropzone } from "dropzone";
import "dropzone/dist/dropzone.css";

const PropertyProfile = ({ addressNumber, addressDirection, addressStreetName, addressUnit, addressZip, propertyType, owner, parcelId, requestType, legalDescription, subdivision, county, state, paramHidePropertyInfo }) => {
    const { selectedBrand } = useContext(BrandContext);
    const { pcUser } = useContext(UserContext);
    const { register, handleSubmit, setValue, watch, control, formState: { errors, isValid } } = useForm();
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [productType, setProductType] = useState(null);
    const [dateInputValue, setDateInputValue] = useState(null);
    const [timeInputValue, setTimeInputValue] = useState(null);
    const [showPropertyInfo, setShowPropertyInfo] = useState(true)
    const [streetDirections, setStreetDirections] = useState([]);
    const [loadingCounties, setLoadingCounties] = useState(false);
    const [stateSelectOptions, setStateSelectOptions] = useState([]);
    const [allCounties, setAllCounties] = useState([]);
    const [countySelectOptions, setCountySelectOptions] = useState([]);
    const [loadingSearchResults, setLoadingSearchResults] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [moreResults, setMoreResults] = useState(false);
    const [propertyInfo, setPropertyInfo] = useState(null);
    const [searchCounty, setSearchCounty] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [toolCounties, setToolCounties] = useState(null);
    const [geoCounty, setGeoCounty] = useState(false);
    const [selectedCountyInfo, setSelectedCountyInfo] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [loadingPropertyResults, setLoadingPropertyResults] = useState(false);
    const [countiesFromZip, setCountiesFromZip] = useState([]);
    const [validZipCode, setValidZipCode] = useState(true);
    const [dropzone, setDropzone] = useState(null);
    const [folderName, setFolderName] = useState(null);

    const watchPropertyAddressStreetNumber = watch("propertyAddressStreetNumber");
    const watchPropertyAddressDirection = watch("propertyAddressDirection");
    const watchPropertyAddressStreetName = watch("propertyAddressStreetName");
    const watchPropertyAddressUnit = watch("propertyAddressUnit");
    const watchPropertyAddressCity = watch("propertyAddressCity");

    useEffect(() => {
        var searchTextTemplate = "";
        if (watchPropertyAddressStreetNumber !== "") searchTextTemplate += watchPropertyAddressStreetNumber + ' ';
        if (watchPropertyAddressDirection !== "") searchTextTemplate += watchPropertyAddressDirection + ' ';
        if (watchPropertyAddressStreetName !== "") searchTextTemplate += watchPropertyAddressStreetName + ' ';
        if (watchPropertyAddressUnit !== "") searchTextTemplate += watchPropertyAddressUnit + ' ';
        setSearchText(searchTextTemplate);
    }, [watchPropertyAddressStreetNumber, watchPropertyAddressDirection, watchPropertyAddressStreetName, watchPropertyAddressUnit]);

    useEffect(() => {
        if (!requestType) return;
        setValue('propertyType', propertyType)
        setValue('propertyOwner', owner)
        setValue('legalDesc', legalDescription)
        setValue('subdivision', subdivision)
        setValue('product', requestType)
        setProductType(requestType);
        setValue('propertyAddressStreetNumber', addressNumber)
        setValue('propertyAddressDirection', addressDirection)
        setValue('propertyAddressStreetName', addressStreetName)
        setValue('propertyAddressUnit', addressUnit)
        if (owner && owner.split(', ').length > 1) {
            setValue('ownerFirstName', owner.split(', ')[1])
            setValue('ownerLastName', owner.split(', ')[0])
        } else {
            setValue('ownerFirstName', owner)
        }
        if (paramHidePropertyInfo) setShowPropertyInfo(false);
        if (county && (!addressZip || addressZip === "")) {
            const getDummyZip = async () => {
                apiPublic.post(`/dummy-data`, { state: state, county: county })
                    .then((response) => {
                        const zip = response.data;
                        setValue('propertyAddressZip', zip);
                        autoFillBasedOnZip(document.getElementById('propertyAddressZip'), "propertyAddressCity", "propertyAddressState");
                    });
            }
            getDummyZip();
        } else {
            setValue('propertyAddressZip', addressZip)
            autoFillBasedOnZip(document.getElementById('propertyAddressZip'), "propertyAddressCity", "propertyAddressState");
        }

    }, [addressNumber, addressDirection, addressStreetName, addressUnit, addressZip, propertyType, owner, parcelId, requestType, legalDescription, subdivision, county]);

    useEffect(() => {
        const getStreetDirections = async () => {
            await apiPublic.get(`/title-and-escrow-order/streetdirections`)
                .then((response) => {
                    var dirs = response.data;
                    if (dirs) {
                        dirs.unshift("");
                        setStreetDirections(dirs);
                    }
                });
        }
        const GetToolCounties = async () => {
            setLoadingCounties(true);
            apiTools.get(`/tool-counties/TaxCalc`)
                .then((response) => {

                    setToolCounties(response.data);
                    setLoadingCounties(false);
                });
        }
        GetToolCounties();

        const getAllCounties = async () => {

            const response = await apiPublic.get(`orderform-counties`);
            var stateSelect = [];
            var stateArr = [...new Set(response.data.map(t => t.stateFullName))];
            stateArr.forEach((s) => { stateSelect.push({ value: s, label: s }) })
            setStateSelectOptions(stateSelect);
            setAllCounties(response.data);
        }
        getAllCounties();
        getStreetDirections();
        checkDayOfWeek();

        if (!dropzone) setDropzone(new Dropzone("div#attachment", { url: process.env.REACT_APP_API_PUBLIC_BASE_URL + "/resware-file/attachments", createImageThumbnails: true, maxFilesize: 15000000, headers: {'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_PUBLIC_SUBSCRIPTION_KEY}}));
        setFolderName((Math.random() + 1).toString(36).substring(2));
    }, []);

    useEffect(() => {
        if (folderName && folderName !== '') { 
            dropzone.options.url = process.env.REACT_APP_API_PUBLIC_BASE_URL + "/resware-file/attachments?folderName=" + folderName;
        }
    }, [folderName]);

    useEffect(() => {
        if (pcUser) {
            setValue('name', pcUser.firstName + ' ' + pcUser.lastName)
            setValue('company', pcUser.businessName)
            setValue('customerPhone', pcUser.mobilePhone)
            setValue('email', pcUser.email)
        }
    }, [pcUser]);

    function handleStateSelectChange(stateSelected) {
        var counties = allCounties.filter(c => c.stateFullName === stateSelected.value);
        var countySelect = [];
        counties.forEach((c) => { countySelect.push({ value: c.countyName, label: c.countyName }) })
        setCountySelectOptions(countySelect);
    };
    function handleCountySelectChange(countySelected) {
        countySelected = countySelected.value.toUpperCase();
        var county;
        var found = find(toolCounties, sc => (sc.county === countySelected));
        if (found) county = found;
        if (county) {
            setGeoCounty(true);
        } else {
            setGeoCounty(false)
        }
        setSearchCounty(countySelected);
        setSelectedCountyInfo(countySelected);
    };

    function checkDayOfWeek() {
        var defaultTime = DateTime.now().plus({ minutes: 120 }).toFormat("HH:mm:ss");
        var defaultDate = DateTime.now();

        if (defaultTime >= "17:00:00") {
            defaultDate = DateTime.now().plus({ days: 1 });
            defaultTime = "08:30:00";
        }

        var dayOfWeek = defaultDate.toFormat("cccc");
        if (dayOfWeek === "Saturday") {
            defaultDate = DateTime.now().plus({ days: 2 });
            defaultTime = "08:30:00";
        } else if (dayOfWeek === "Sunday") {
            defaultDate = DateTime.now().plus({ days: 1 });
            defaultTime = "08:30:00";
        }

        defaultDate = defaultDate.toFormat("yyyy-MM-dd");
        setDateInputValue(defaultDate);
        setTimeInputValue(defaultTime);
    }


    const checkTime = (e) => {
        if (e.target.value >= "17:00:00") {
            setDateInputValue(DateTime.now().plus({ days: 1 }).toFormat("yyyy-MM-dd"));
            var defaultTime = "08:30:00";
            setTimeInputValue(defaultTime);
        } else {
            setTimeInputValue(e.target.value);
        }
    };

    const saveProduct = (e) => {
        setProductType(e.target.value);
        setValue('product', e.target.value, { shouldValidate: true });
    };

    const onSubmit = data => {
        var byDate = (!data.neededByDate) ? dateInputValue : data.neededByDate;

        const emailOrderObject = {
            name: data.name,
            company: data.company,
            customerAddress: data.customerAddress,
            customerZip: data.customerZip,
            customerCity: data.customerCity,
            customerState: data.customerState,
            customerPhone: data.customerPhone,
            customerEmail: data.email,
            propertyAddressStreetNumber: data.propertyAddressStreetNumber,
            propertyAddressStreetName: data.propertyAddressStreetName,
            propertyAddressDirection: data.propertyAddressDirection,
            propertyAddressUnit: data.propertyAddressUnit,
            propertyAddressZip: data.propertyAddressZip,
            propertyAddressCity: data.propertyAddressCity,
            propertyAddressState: state ? state : data.propertyAddressState,
            propertyAddressCounty: county ? toTitleCase(county) : toTitleCase(searchCounty),
            legalDesc: data.legalDesc,
            parcelId: propertyInfo?.parcelId,
            propertyOwnerFirstName: data.ownerFirstName,
            propertyOwnerLastName: data.ownerLastName,
            propertyType: data.propertyType,
            product: data.product,
            neededByDate: byDate,
            neededByTime: timeInputValue,
            subdivision: data.subdivision,
            deliverMethod: data.deliverMethod,
            notes: data.notes,
            fileFolderName: folderName,
            fileUpload: dropzone.getAcceptedFiles().map(f => f.name).join(', '),
            brand: selectedBrand
        }

        const sendEmail = async () => {
            await apiPublic.post(`/web-order`, emailOrderObject)
                .then((response) => {
                    const orderId = response.data;
                    if (!orderId) { 
                        setEmailSent(true);
                        return;
                    }
                    setEmailSent(true);                        
                    setLoading(false);
                });
        }
        setLoading(true);
        sendEmail();
    }

    const handleCustomerPhoneInput = (e) => {
        const formattedPhoneNumber = FormatPhoneInput(e.target.value);
        setValue('customerPhone', formattedPhoneNumber, { shouldValidate: true });

    };

    const autoFillBasedOnZip = async (zipInput, cityFormField, stateFormField, countyFormField, countySystemIdFormField) => {
        setValue(zipInput.name, zipInput.value, { shouldValidate: true });
        if (zipInput.value.length === 5) {
            apiPublic.get(`/zipcodeinfo/${zipInput.value}`)
                .then((response) => {
                    if (!response.data) { 
                        setValidZipCode(false);
                        return;
                    }
                    setValidZipCode(true);
                    setCountiesFromZip(response.data.counties)
                    setValue(cityFormField, response.data.city, { shouldValidate: true });
                    setValue(stateFormField, response.data.stateAbbr, { shouldValidate: true });
                    if (countyFormField) {
                        setValue(countyFormField, response.data.county, { shouldValidate: true });
                    }
                    if (countySystemIdFormField) {
                        setValue(countySystemIdFormField, response.data.countySystemId, { shouldValidate: true });
                    }
                });
        }
    };

    function doSearch() {
        setNoResults(false);
        setMoreResults(false);
        setPropertyInfo(null);
        if (searchText === null) {
            setSearchText("");
            return;
        }

        if (searchCounty !== null && searchCounty !== "" && searchText !== null && searchText !== "") {
            setLoadingSearchResults(true);
            var selectedCounty = find(toolCounties, sc => (sc.county === searchCounty));
            setSelectedCountyInfo(selectedCounty);
            var searchCriteria = {
                address: searchText,
                county: selectedCounty.county,
                state: selectedCounty.state
            };
            getSearchResults(searchCriteria);
        }
    }
    const getSearchResults = async (searchCriteria) => {
        apiTools.get(`/property-search?state=${searchCriteria.state}&county=${searchCriteria.county}&searchType=siteAddr&value=${searchCriteria.address}`)
            .then((response) => {
                setSearchResults(response.data.records);
                if (response.data.records.length === 0) {
                    setNoResults(true);
                }
                if (response.data.totalCount > response.data.currentCount) {
                    setMoreResults(true);
                }
                setLoadingSearchResults(false);
            });
    }
    function fillPropertyInfo(property) {
        setLoadingPropertyResults(true);
        setPropertyInfo(null);
        apiTools.get(`/property-profile?state=${selectedCountyInfo.state}&county=${selectedCountyInfo.county}&parcelId=${property.parcelId}`)
            .then((response) => {
                setPropertyInfo(response.data);
                setValue('ownerFirstName', response.data.ownerName.split(', ')[1]);
                setValue('ownerLastName', response.data.ownerName.split(', ')[0]);
                setValue('propertyAddressStreetNumber', response.data.siteHouseNumber);
                setValue('propertyAddressDirection', response.data.siteDirectionPrefix);
                setValue('propertyAddressStreetName', response.data.siteStreetName + ' ' + response.data.siteStreetType);
                setValue('propertyAddressUnit', response.data.siteUnit);
                setValue('legalDesc', response.data.legalDescription, { shouldValidate: true });
                setValue('propertyAddressZip', response.data.siteZip, { shouldValidate: true });
                autoFillBasedOnZip(document.getElementById("propertyAddressZip"), "propertyAddressCity", "propertyAddressState");
                setLoadingPropertyResults(false);
            });
    }

    useEffect(() => {
        if (!searchCounty || !countiesFromZip || countiesFromZip.length === 0) return;
        setValidZipCode(countiesFromZip.length > 0 && searchCounty && countiesFromZip.some(c => c.county.toLowerCase() === searchCounty.toLowerCase()))
    }, [searchCounty, countiesFromZip])

    return (
        <>
            {!requestType && <><Helmet>
                <title>Property Profile : {selectedBrand.brandName} </title>
            </Helmet>
                <h1>Property Profile</h1></>}
            <div className="row">
                <div className="col-12">
                    {!requestType && <p>Want a faster way to get your Property Profile? <Link to="/propertyone">Do it yourself in seconds with PropertyOne.</Link></p>}

                    {emailSent && <>
                        <p><strong>Thank you for your order! A team member will be contacting you soon.</strong></p>
                        <p>Enter another order:<br /><Link to="/title-and-escrow-order" className="btn btn-primary btn-sm">Title & Escrow Order</Link> <Link to="/home-portfolio-form" className="btn btn-primary btn-sm">Home Portfolio</Link> <Link to="/property-profile" reloadDocument className="btn btn-primary btn-sm">Property Profile</Link></p>
                    </>}
                    {!emailSent && loading && <><p className="fw-bold text-center">Submitting. Please wait for the confirmation.</p><SpinnerLoader /></>}
                    {!loading && !emailSent &&
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {requestType && <div className="row">
                                <div className="col-12 col-sm-6 col-md-8 mb-1">
                                    <input type="submit" value="Submit Order" className="btn btn-primary float-start" />
                                </div>
                                <div className="col-sm-4 col-md-3"></div>
                            </div>}
                            <h2>Customer Information:</h2>

                            <div className="row pb-1">
                                <label htmlFor="name" className="col-12 col-sm-4 col-md-3 text-md-end required">
                                    Your Name:
                                </label>
                                <div className="col-12 col-sm-6 col-md-8">
                                    <input autoComplete="name" name="name" type="text" id="name" className="form-control" {...register("name", { required: true })} />
                                    {errors?.name?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                            <div className="row pb-1">
                                <label htmlFor="company" className="col-12 col-sm-4 col-md-3 text-md-end required">
                                    Company:
                                </label>
                                <div className="col-12 col-sm-6 col-md-8">
                                    <input name="company" type="text" id="company" className="form-control" {...register("company", { required: true })} />
                                    {errors?.company?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                            <div className="row pb-1">
                                <label htmlFor="customerAddress" className="col-12 col-sm-4 col-md-3 text-md-end">
                                    Company Address:
                                </label>
                                <div className="col-12 col-sm-6 col-md-8 pb-2 pb-lg-0">
                                    <input autoComplete="street-address" name="customerAddress" type="text" id="customerAddress" className="form-control" {...register("customerAddress")} />
                                </div>
                            </div>
                            <div className="row pb-1">
                                <div className="offset-sm-3 col-8 col-lg-3 col-xl-2 pb-2 pb-lg-0">
                                    <input autoComplete="postal-code" name="customerZip" type="text" id="customerZip" maxLength="5" className="form-control" placeholder="Zip Code" {...register("customerZip")} onChange={(e) => { autoFillBasedOnZip(e.target, "customerCity", "customerState",) }} />
                                </div>
                                <div className="offset-sm-3 offset-lg-0 col-8 col-lg-3 pb-2 pb-lg-0">
                                    <input name="customerCity" type="text" id="customerCity" className="form-control" placeholder="City" {...register("customerCity")} />
                                </div>
                                <div className="offset-sm-3 offset-lg-0 col-8 col-lg-2 col-xl-1">
                                    <input name="customerState" type="text" maxLength="2" id="customerState" className="form-control" placeholder="State" {...register("customerState")} />
                                </div>
                            </div>
                            <div className="row pb-1">
                                <label htmlFor="customerPhone" className="col-12 col-sm-4 col-md-3 text-md-end required">Phone Number:
                                </label>
                                <div className="col-8 col-lg-3 pb-2 pb-lg-0">
                                    <input autoComplete="tel" name="customerPhone" type="tel" id="customerPhone" className="form-control" {...register("customerPhone", { required: true, minLength: 12 })} onChange={(e) => handleCustomerPhoneInput(e)} />
                                    {errors?.customerPhone?.type === "required" && <small className="text-danger">This field is required</small>}
                                    {errors?.customerPhone?.type === "minLength" && <small className="text-danger">Enter a valid phone number</small>}
                                </div>
                            </div>
                            <div className="row pb-1">
                                <label htmlFor="email" className="col-12 col-sm-4 col-md-3 text-md-end required">Email:</label>
                                <div className="col-12 col-sm-6 col-md-8">
                                    <input autoComplete="email" name="email" id="email" className="form-control" {...register("email", { required: true, pattern: GetEmailRegex() })} />
                                    {errors?.email?.type === "required" && <small className="text-danger">This field is required</small>}
                                    {errors?.email?.type === "pattern" && <small className="text-danger">Please enter a valid email address</small>}
                                </div>
                            </div>
                            <div className={showPropertyInfo ? "" : "d-none"}>
                                <h2 className="pt-1">Property Information:</h2>
                                {loadingCounties && <SpinnerLoader />}
                                {!requestType && !loadingCounties && stateSelectOptions.length > 0 &&
                                    <>
                                        <div className="row pb-1">
                                            <label className="col-12 col-sm-4 col-md-3 text-md-end required">Property State:
                                            </label>
                                            <div className="col-12 col-sm-6 col-md-8">
                                                <Controller control={control}
                                                    name="State"
                                                    id="State"
                                                    rules={{ required: true }}
                                                    render={({ field }) => {
                                                        const { onChange, name } = field;
                                                        return (
                                                            <Select
                                                                placeholder="Select State"
                                                                aria-label="Select State"
                                                                name={name}
                                                                classNamePrefix="no-localization"
                                                                options={stateSelectOptions}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                    handleStateSelectChange(e);
                                                                }}
                                                                components={{ Input: ReactSelectInputNoAutoComplete }}
                                                            />);
                                                    }}
                                                />
                                                {errors?.State?.type === "required" && <small className="text-danger">This field is required</small>}
                                            </div>
                                        </div>
                                    </>
                                }
                                {!loadingCounties && countySelectOptions.length > 0 &&
                                    <>
                                        <div className="row pb-1">
                                            <label className="col-12 col-sm-4 col-md-3 text-md-end required">Property County:
                                            </label>
                                            <div className="col-12 col-sm-6 col-md-8">
                                                <Controller control={control}
                                                    name="County"
                                                    id="County"
                                                    rules={{ required: true }}
                                                    render={({ field }) => {
                                                        const { onChange, name } = field;
                                                        return (
                                                            <Select
                                                                placeholder="Select County"
                                                                aria-label="Select County"
                                                                name={name}
                                                                classNamePrefix="no-localization"
                                                                options={countySelectOptions}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                    handleCountySelectChange(e);
                                                                }}
                                                                components={{ Input: ReactSelectInputNoAutoComplete }}
                                                            />);
                                                    }}
                                                />
                                                {errors?.County?.type === "required" && <small className="text-danger">This field is required</small>}
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="row pb-1">
                                    <label className="col-12 col-md-3 text-md-end required">
                                        Property Address:
                                    </label>
                                    <div className="col-6 pb-1 col-md-2 pb-md-0">
                                        <input name="propertyAddressStreetNumber" autoComplete="chrome-off" aria-label="Street Number" placeholder="Street Number" type="text" className="form-control" maxLength="15" {...register("propertyAddressStreetNumber", { required: showPropertyInfo })} />
                                        {errors?.propertyAddressStreetNumber?.type === "required" && <small className="text-danger">Required</small>}
                                    </div>
                                    <div className="col-6 col-md-2">
                                        <select name="propertyAddressDirection" className="form-select" aria-label="Street Direction" {...register("propertyAddressDirection")}>
                                            {streetDirections.map((d, i) => {
                                                return <option key={i} value={d}>{d}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-6 col-md-3">
                                        <input name="propertyAddressStreetName" autoComplete="chrome-off" aria-label="Street Name" placeholder="Street Name" type="text" className="form-control" {...register("propertyAddressStreetName", { required: showPropertyInfo })} />
                                        {errors?.propertyAddressStreetName?.type === "required" && <small className="text-danger">This field is required</small>}
                                    </div>
                                    <div className="col-6 col-md-2">
                                        <input name="propertyAddressUnit" aria-label="Address Unit" placeholder="Unit" type="text" autoComplete="chrome-off" className="form-control" {...register("propertyAddressUnit")} />
                                    </div>
                                </div>

                                <div className="row pb-1">
                                    <span className="col-0 col-md-3"></span>
                                    <div className="col-12 col-md-9">
                                        <div className="row">
                                            <div className="col-12 pb-1 col-md-2 pb-md-0">
                                                <input name="propertyAddressZip" autoComplete="chrome-off" aria-label="Property Zip" type="text" id="propertyAddressZip" maxLength="5" className="form-control" placeholder="Zip Code"
                                                    {...register("propertyAddressZip", { required: showPropertyInfo })} onChange={(e) => { autoFillBasedOnZip(e.target, "propertyAddressCity", "propertyAddressState",); }} />
                                                <div className="small">Zip</div>
                                                {errors?.propertyAddressZip?.type === "required" && <small className="text-danger">Required</small>}
                                            </div>
                                            <div className={(watchPropertyAddressCity === "") ? "d-none" : "col-12 pb-1 col-md-4 pb-md-0"}>
                                                <input name="propertyAddressCity" aria-label="Property City" type="text" disabled={true} id="propertyAddressCity" className="form-control" {...register("propertyAddressCity")} />
                                                <div className="small">City</div>
                                            </div>
                                            <div className={(watchPropertyAddressCity === "") ? "d-none" : "col-6 pb-1 col-md-2 pb-md-0"}>
                                                <input name="propertyAddressState" aria-label="Property State" type="text" disabled={true} maxLength="2" id="propertyAddressState" className="form-control" {...register("propertyAddressState")} />
                                                <div className="small">State</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">{!validZipCode && <small className="text-danger">This zip code is not recognized{searchCounty && " in " + searchCounty + " county"}.</small>}</div>
                                        </div>
                                    </div>
                                </div>
                                {geoCounty && !requestType && <div className="row pb-2">
                                    <div className="offset-sm-3 col-8 col-lg-3 col-xl-2">
                                        <input className="btn btn-primary" type="button" value="Search" onClick={() => doSearch()} />
                                    </div>
                                </div>}

                                {(loadingSearchResults || loadingPropertyResults) && <SpinnerLoader />}
                                {!loadingSearchResults && noResults &&
                                    <div className="row pt-0 pb-2">
                                        <div className="col offset-0 offset-md-3">
                                            <span className="fw-bold">No results found.</span><br />
                                            Please try your search again.
                                        </div>
                                    </div>
                                }
                                {!loadingSearchResults && searchResults.length > 0 && !loadingPropertyResults && propertyInfo == null &&
                                    <>
                                        {searchResults.length > 1 && <div className="row offset-0 offset-lg-3"><div className="col-12 fw-bold">Multiple properties were found. Please select a property:</div></div>}
                                        {searchResults.length === 1 && <div className="row offset-0 offset-lg-3"><div className="col-12 fw-bold">Please confirm this is the property you are looking for:</div></div>}
                                        <div className="row offset-0 offset-lg-3">
                                            <div className="col-5 col-lg-4 fw-bold">Address</div>
                                            <div className="col-5 col-lg-4 fw-bold">Owner</div>
                                            <div className="col-2"></div>
                                            <div className="col-0 col-lg-2"></div>
                                        </div>
                                        {orderBy(searchResults, ['siteAddress']).map((p, idx) =>
                                            <div className="row offset-0 offset-lg-3 pb-2" key={idx}>
                                                <div className="col-5 col-lg-4 lg-sm no-localization">{p.siteAddress}</div>
                                                <div className="col-5 col-lg-4 lg-sm no-localization">{p.ownerName}</div>
                                                <div className="col-2">
                                                    <button className="btn btn-primary btn-sm" onClick={() => fillPropertyInfo(p)}>Select</button>
                                                </div>
                                                <div className="col-0 col-lg-2"></div>
                                            </div>)
                                        }
                                        {moreResults && <div className="row offset-0 offset-lg-3"><div className="col-12 fw-bold">More than {searchResults.length} properties were found, please enter a more precise address.</div></div>}
                                    </>
                                }
                                <div className="row pb-1">
                                    <label htmlFor="legalDesc" className="col-12 col-sm-4 col-md-3 text-md-end required">
                                        Legal Desc:
                                    </label>
                                    <div className="col-12 col-sm-6 col-md-8 no-localization">
                                        <textarea name="legalDesc" rows="3" cols="20" id="legalDesc" className="form-control" {...register("legalDesc", { required: showPropertyInfo })} ></textarea>
                                        {errors?.legalDesc?.type === "required" && <small className="text-danger">This field is required</small>}
                                    </div>
                                </div>
                                <div className="row pb-1">
                                    <label htmlFor="ownerFirstName" className="col-12 col-sm-4 col-md-3 text-md-end">
                                        Owner: (if avail.)
                                    </label>
                                    <div className="col-6 col-sm-3 col-md-4 pb-2 pb-lg-0 no-localization">
                                        <input autoComplete="chrome-off" placeholder="First Name" name="ownerFirstName" type="text" id="ownerFirstName" className="form-control" {...register("ownerFirstName")} />
                                    </div>
                                    <div className="col-6 col-sm-3 col-md-4 pb-2 pb-lg-0 no-localization">
                                        <input autoComplete="chrome-off" placeholder="Last Name" name="ownerLastName" type="text" id="ownerLastName" className="form-control" {...register("ownerLastName")} />
                                    </div>
                                </div>
                                <div className="row pb-1">
                                    <label htmlFor="propertyType" className="col-12 col-sm-4 col-md-3 text-md-end">
                                        Type:
                                    </label>
                                    <div className="col-12 col-sm-6 col-md-8 pb-2 pb-lg-0">
                                        <select name="propertyType" id="propertyType" className="form-select" aria-label="Select Type" {...register("propertyType")}>
                                            <option value="Residential">Residential</option>
                                            <option value="Commercial">Commercial</option>
                                            <option value="Agricultural">Agricultural</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <h2 className="pt-1">Order Information:</h2>
                            {!requestType && <div className="row pb-1">
                                <div className="col offset-0 offset-md-3">
                                    Listing Package/Property Profile includes deeds, map, taxes, ownership & CC&Rs.
                                </div>
                            </div>}
                            <div className="row pb-1">
                                <label htmlFor="product" className="col-12 col-sm-4 col-md-3 text-md-end required">
                                    Product:
                                </label>
                                <div className="col-12 col-sm-6 col-md-8 pb-2 pb-lg-0">
                                    <select disabled={requestType} name="product" id="product" className="form-select" aria-label="Select Product" {...register("product", { required: true })} onChange={(e) => { saveProduct(e); }}>
                                        <option key="-1" value="">- SELECT -</option>
                                        <option value="Listing Package/Property Profile">Listing Package/Property Profile</option>
                                        <option value="CC&Rs Only">CC&Rs Only</option>
                                        <option value="Ownership">Ownership</option>
                                        <option value="Deeds">Deeds</option>
                                    </select>
                                    {errors?.product?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                            <div className="row pb-1">
                                <label className="col-12 col-sm-4 col-md-3 text-md-end required">
                                    Needed By Date/Time:
                                </label>
                                <div className="col-12 col-sm-3 col-md-4">
                                    <input id="neededByDate" className="form-control" aria-label="Need by Date" type="date" required {...register("neededByDate")} defaultValue={dateInputValue} min={dateInputValue} />
                                </div>
                                <div className="col-12 col-sm-3 col-md-4">
                                    <input id="neededByTime" className="form-control" aria-label="Need by Time" type="time" defaultValue={timeInputValue} {...register("neededByTime")} onChange={(e) => checkTime(e)} />
                                </div>
                            </div>

                            <div className={productType === "CC&Rs Only" ? "row pb-1" : "d-none"}>
                                <label htmlFor="subdivision" className="col-12 col-sm-4 col-md-3 text-md-end required">
                                    Subdivision:
                                </label>
                                <div className="col-12 col-sm-6 col-md-8 pb-2 pb-lg-0">
                                    <input name="subdivision" type="text" id="subdivision" className="form-control" {...register("subdivision", { required: productType === "CC&Rs Only" })} />
                                    {errors?.subdivision?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>


                            <div className="row pb-1">
                                <label htmlFor="deliverMethod" className="col-12 col-sm-4 col-md-3 text-md-end">
                                    Deliver Via:
                                </label>
                                <div className="col-12 col-sm-6 col-md-8 pb-2 pb-lg-0">
                                    <select name="deliverMethod" id="deliverMethod" className="form-select" aria-label="Select Deliver Method" {...register("deliverMethod")}>
                                        <option value="Email">Email</option>
                                        <option value="Fax">Fax</option>
                                        <option value="Hand Delivery">Hand Delivery</option>
                                        <option value="Pick Up">Pick Up</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row pb-1">
                                <label htmlFor="notes" className="col-12 col-sm-4 col-md-3 text-md-end">
                                    Notes:
                                </label>
                                <div className="col-12 col-sm-6 col-md-8 pb-2 pb-lg-0">
                                    <textarea name="notes" rows="2" cols="20" id="notes" className="form-control" {...register("notes")}></textarea>
                                </div>
                            </div>
                            <div className="row pb-1">
                                <label htmlFor="attachment" className="col-12 col-md-3 text-md-end">
                                    Attach File(s):<br />
                                </label>
                                <div className="col-12 col-sm-6 col-md-8">
                                    <div id="attachment" className="dropzone dropzone-previews border"/>
                                </div>
                            </div>
                            {!requestType && <div className="row">
                                <div className="col-sm-4 col-md-3"></div>
                                <div className="col-12 col-sm-6 col-md-8 mb-1">
                                    <input type="submit" value="Submit Order" className="btn btn-primary float-end" disabled={!validZipCode || (!isValid && !isEmpty(errors))} />
                                    {(!validZipCode || (!isValid && !isEmpty(errors))) && <div className="text-danger">There was a problem with the form entries. Please see the messages above.</div>}  
                                </div>
                            </div>}
                        </form>}
                </div>
            </div>
        </>
    );
}

export default PropertyProfile;